<template>
  <activity
      :id="projectId"
      :type="type"
      v-if="ifHasPermission(['pm-view-activities'])"
  />
</template>
<script>
import Activity from '../../components/activity/Activity.vue';

export default {
  name: 'ActivityProject',
  data() {
    return {
      type: 'project',
      projectId: this.$route.params.projectId,
    };
  },
  components: {
    Activity,
  },
  beforeMount() {
    this.$store.commit('SET_ACTIVE', 'activity');
  },
};
</script>
